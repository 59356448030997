

$(document).ready(function(){
	$(window).on("scroll", function(e) {
		e.preventDefault();

		var scrolled = $(this).scrollTop();
		updateTimeJourney(scrolled, $("#time-journey-display"));
	});

	//1. Aufruf um active Klasse zusetzen
	updateTimeJourney($(window).scrollTop(), $("#time-journey-display"));
});

function updateTimeJourney(scrolled, display) {
	//HalfWindowHeight
	var winHalfHeight = $(window).height() / 2;
	//Get Timejourney Wrapper + items
	var time_journey_wrapper = $(".time-journey");
	var time_items = time_journey_wrapper.find(".time-item");

	var itemDistance = [];
	var scrolledMiddle = [];

	for(var i = 0; i < time_items.length; i++) {
		itemDistance[i] = time_items.eq(i).offset().top;
		scrolledMiddle[i] = itemDistance[i] - scrolled - winHalfHeight;

		if(scrolledMiddle[i] < 0 ) {
			scrolledMiddle[i] *= -1;
		}
	}

	time_items.removeClass("timeline__active-item");

	var newCurrentItem;
	if(scrolled == 0) {																//Top is reached (first item active)
		newCurrentItem = time_items.eq(0);
	}
	else if($(window).scrollTop() + $(window).height() == $(document).height()) {	//Bottom is reached (last item active)
		var _index = itemDistance.indexOf(Math.max(itemDistance));
		newCurrentItem = time_items.eq(_index);
	}
	else {
		newCurrentItem = time_items.eq($.inArray(Math.min.apply(Math, scrolledMiddle), scrolledMiddle));
	}

	newCurrentItem.addClass("timeline__active-item");

	//Optional Display value
	if(display.length > 0) {
		display.html(newCurrentItem.attr("data-time-value"));
	}

}

