$(document).ready(function(){
	var pswp = '<!-- Root element of PhotoSwipe. Must have class pswp. --> <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"> <!-- Background of PhotoSwipe. It\'s a separate element as animating opacity is faster than rgba(). --> <div class="pswp__bg"></div> <!-- Slides wrapper with overflow:hidden. --> <div class="pswp__scroll-wrap"> <!-- Container that holds slides. PhotoSwipe keeps only 3 of them in the DOM to save memory. Don\'t modify these 3 pswp__item elements, data is added later on. --> <div class="pswp__container"> <div class="pswp__item"></div> <div class="pswp__item"></div> <div class="pswp__item"></div> </div> <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. --> <div class="pswp__ui pswp__ui--hidden"> <div class="pswp__top-bar"> <!-- Controls are self-explanatory. Order can be changed. --> <div class="pswp__counter"></div> <button class="pswp__button pswp__button--close" title="Close (Esc)"></button> <!--button class="pswp__button pswp__button--share" title="Share"></button--> <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button> <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button> <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR --> <!-- element will get class pswp__preloader--active when preloader is running --> <div class="pswp__preloader"> <div class="pswp__preloader__icn"> <div class="pswp__preloader__cut"> <div class="pswp__preloader__donut"></div> </div> </div> </div> </div> <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"> <div class="pswp__share-tooltip"></div> </div> <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"> </button> <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"> </button> <div class="pswp__caption"> <div class="pswp__caption__center"></div> </div> </div> </div> </div>';

	$('body').append(pswp);

	photoswipe_call('.pswp-gallery',{});


});

var photoswipe_call = function(galleryIdentifier,options){
	// define options (if needed)
		$.extend(options,{
		    // optionName: 'option value'
		    // for example:
			index: 0, // start at first slide
			figureElement: 'figure'
		}
	);

	$(galleryIdentifier).each(function(k){
		var _this = this;
		var $this = $(this);
		$this.attr('data-pswp-uid',k);
		$this.find(options.figureElement).attr('data-pswp-gallery',k);
		var elements = $this.find(options.figureElement+' a[data-size]');
		for(var i = 0; i < elements.length; i++) {
			(function (_i){
				elements.eq(i).on('click', function(e) {
					if($(this).closest('.slick-track').find(".slick-cloned").length > 0) {
						_i = _i+1;
					}
					e.preventDefault();
					openPhotoSwipe( _i, _this );
				});
			}(i))
		}
	});

	var openPhotoSwipe = function(index,gallery, disableAnimation, fromURL){
		var pswpElement = $('.pswp').get(0);

		var items = function() {
            var items = [];
            $(gallery).find('a[data-size]').each(function() {
                var $href   = $(this).attr('href'),
                    $size   = $(this).data('size').split('x'),
                    $width  = $size[0],
                    $height = $size[1];

                var item = {
                    src : $href,
                    w   : $width,
                    h   : $height
                };

                items.push(item);
            });
            return items;
        }();

		$.extend(options,{
            // define gallery index (for URL)
            galleryUID: $(gallery).data('pswp-uid'),
			index: parseInt(index, 10)
        });
		gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
	};

};
