var breakpoint_md = 900;
var breakpoint_sm = 768;


$(document).ready(function(){
	$('.slick--hero').slick(
			{
				dots: true,
				arrows: false,
				adaptiveHeight: true,
				mobileFirst: true,
				draggable: false,
				swipe: true,
				responsive: [
					{
						breakpoint: breakpoint_sm,
						settings: {}
					},
					{
						breakpoint: breakpoint_md,
						settings: {}
					}
				]

			}
	);
	$('.slick--center').slick(
			{
				dots: true,
				arrows: true,
				adaptiveHeight: false,
				mobileFirst: true,
				draggable: false,
				swipe: true,
				centerMode: true,
				centerPadding: "0%",
				responsive: [
					{
						breakpoint: breakpoint_sm,
						settings: {
							centerPadding: "10%",
							centerMode: true,

						}
					}
				]

			}
	);

	$('.slick--multiple-items').slick(
			{
				dots: true,
				arrows: true,
				adaptiveHeight: false,
				mobileFirst: true,
				draggable: false,
				swipe: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: breakpoint_sm,
						settings: {
							slidesToShow: 3
						}
					}
				]

			}
	);


	$('.slick--cards').slick({

		dots: false,
		arrows: true,
		swipe: true,
		slidesToShow: 3,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: breakpoint_sm,
				settings: {
					slidesToShow: 1
				}
			}
		]

	});


});



