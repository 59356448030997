//EVENTS
//ATTENTION use bars.listener.LISTENER.push(...) to add events!

//onLoad
$(window).on('load', function(){
	bars.listener.load.forEach(function(func){
		if(typeof func === 'function'){
			func();
		}
	});
});









//document ready
$(document).ready(function($){
	bars.listener.ready.forEach(function(func){
		if(typeof func === 'function'){
			func();
		}
	});
});









//resize
$(window).on('resize',function(){
	bars.listener.resize.forEach(function(func){
		if(typeof func === 'function'){
			func();
		}
	});
});









//scroll
$(window).scroll(function(){
	bars.listener.scroll.forEach(function(func){
		if(typeof func === 'function'){
			func();
		}
	});
});









//ausführen wenn alle deferred objects "done" sind
if(bars.deferred.__toArray().length > 0){
	$.when.apply(null, bars.deferred.__toArray()).done(function(){
		bars.deferred.__resolve();
	});
}else{
	bars.deferred.__resolve();
}