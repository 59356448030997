//SMOOTH SCROLL
$('.anchor').addClass('js--anchor');
$('.anchor-responsive').addClass('js--anchor-responsive');

$('.js--anchor, .js--anchor-responsive').click(function(event){
	var elAttr = $(this).attr('href');

	//prevent that an non anchor link is blocked
	if(elAttr.indexOf('#') !== -1){
		event.preventDefault();

		$('body,html').animate({
			scrollTop: $(elAttr).offset().top - 200
		}, 1000, 'swing');
	}
});